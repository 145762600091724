<footer class="usa-footer">
  <button
    class="semantic-button return-to-top-row"
    id="scrollBar"
    title="Go to top"
    (click)="topFunction()"
  >
    Return to top
  </button>
  <div class="links-row">
    <a [href]="link.route" class="footer-link" *ngFor="let link of links">
      {{ link.text }}
    </a>
  </div>
  <div class="footer-body">
    <div class="footer-info">
      <div class="usa-footer-logo-img">
        <img
          alt="USDA logo. Forest Service Logo."
          class="mobile"
          src="/assets/img/usda/footer-logo-mobile.png"
        />
        <img
          alt="USDA logo. Forest Service Logo. Forest Service U.S. Department of Agriculture"
          class="desktop"
          src="/assets/img/usda/usda-fs-logo.png"
        />
      </div>

      <div class="ead-title">
        <h3 class="ead-text">Enterprise Application Development - CIO</h3>
      </div>
      <div class="contact-info">
        <p>
          Email Help Desk at
          <a class="link" href="mailto: chd@usda.gov">chd&#64;usda.gov</a>
        </p>
        <p>
          Send feedback to:
          <a class="link" href="mailto: sm.fs.ciao_support@usda.gov">
            sm.fs.ciao_support&#64;usda.gov
          </a>
        </p>
      </div>

      <div class="social-icons">
        <a
          (click)="
            clickLink({ route: 'https://www.facebook.com/USForestService' })
          "
          class="social-icon"
        >
          <img src="assets/img/icons/facebook.svg" alt="Dot gov"
        /></a>
        <a
          (click)="clickLink({ route: 'https://twitter.com/forestservice' })"
          class="social-icon"
          ><img src="assets/img/icons/twitter.svg" alt="Dot gov"
        /></a>
        <a
          (click)="
            clickLink({
              route: 'https://www.youtube.com/user/usdaForestService'
            })
          "
          class="social-icon"
          ><img src="assets/img/icons/youtube.svg" alt="Dot gov"
        /></a>
        <a
          (click)="clickLink({ route: 'https://www.usda.gov/rss-feeds' })"
          class="social-icon"
          ><img src="assets/img/icons/rssFeed.svg" alt="Dot gov"
        /></a>
      </div>
    </div>
  </div>
</footer>
<p class="version-text">
  <a [routerLink]="'/help/info/version-history'">{{ versionInfoString }}</a>
</p>
